.opp-nav {
    $top: &;

    pointer-events: none;

    * {
        pointer-events: initial;
	}

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 0;
    z-index: 10;
    text-align: center;

    &--bar {
        width: 6rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: block;
		padding: 1rem 0;
		pointer-events: none;

        @include mobile {
            top: 0;
            left: 0;
            right: 0;
			height: 6rem;
			width: initial;
        }
    }

    &--inner {
        width: 100%;
        max-width: 1600px;
        position: absolute;
        // display: flex;
        // left: -100vw;
        transform: translateX(-100%);
        height: 100%;
        transition: 450ms ease;
        background: #000;

        @include mobile {
            left: 0;
            transform: translateY(-100%);
            padding: 6rem 3rem;
        overflow-y: scroll;
        }
    }

    &--menu {
        @include opp-list;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10rem;
        opacity: 0;
        transition: 450ms ease 300ms;

        @include tablet {
            left: 6rem;
        }

        @include mobile {
            position: static;
            top: initial;
            transform: none;
            margin-bottom: 3rem;
            font-size: 3rem;
        }

        #{$top}--open & {
            opacity: 1;
        }

        &--item {
            @include opp-list--item;
            margin: .25em 0;
            line-height: 1;

            &__active a {
                color: #fff;
            }
        }
    }

    &--logo {
        text-align: center;
        display: block;

        svg {
            width: 128px;
        }
    }

    &--contact {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10rem;
        text-align: left;
        opacity: 0;
        transition: 450ms ease 450ms;
        text-align: center;

        @include tablet {
            right: 5rem;
        }

        @include mobile {
            position: static;
            top: initial;
            transform: none;
        }

        #{$top}--open & {
            opacity: 1;
        }

        p {
            margin-top: .5em;

            &:not(:last-child) {
                // margin-bottom: 2em;
            }
        }

        h3 {
            margin-bottom: .5em;
            margin-top: 2em;
        }
    }

    &--open {
        background: #fff;
        transform: none;
        @include mobile {
            // top: 0;
        }
	}
}

.opp-logo {
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 4rem;
    height: auto;
    &--o,
    &--p1,
    &--p2 {
        fill: none;
        stroke: #124058;
    }
    &--o {
        stroke-width: 12px;
        stroke-dasharray: 223;
        stroke-dashoffset: 223;
        animation: draw-o 1s ease 1s forwards;
    }
    &--p1,
    &--p2 {
        stroke-width: 3px;
        stroke-dasharray: 47;
        stroke-dashoffset: 47;
    }
    /*&--p1 {
        animation: draw-p 1s ease .5s forwards;
    }
    &--p2 {
        animation: draw-p 1s ease forwards;
	}*/
	
	@include mobile {
		width: 3rem;
	}
}

@keyframes draw-o {
    from {
        stroke-dashoffset: 223;
    }
    to {
        stroke-dashoffset: 0;
    }
}

/*@keyframes draw-p {
    from {
        stroke-dashoffset: 47;
    }
    to {
        stroke-dashoffset: 0;
    }
}*/

.opp-hamburger {
    $top: &;
    position: absolute;
    top: 50%;
    left: 2rem;
    z-index: 11;
    transform: translateY(-50%);
    cursor: pointer;
    display: inline-block;
    border: none;
    height: 2rem;
    width: 2rem;
    outline: none;
    transform-origin: left;
    animation: growRight 900ms ease forwards;
    background-color: transparent;

    @include mobile {
        left: initial;
        right: 1rem;
        top: 1.5rem;
    }

    &--line {
        $weight: 4px;
        height: $weight;
        width: 100%;
        // border-radius: $weight / 2;
        background: #124058;
        position: absolute;
        box-shadow: 0 5px 15px rgba(#000, 0.1);
        left: 0;
        transition: 300ms ease;
        top: calc(50% - #{$weight} / 2);
        &:first-child {
            transform: translateY(-10px);
        }
        &:nth-child(2) {
            width: 80%;
        }
        &:last-child {
            transform: translateY(10px);
            width: 90%;
        }
    }
    &--close {
        #{$top}--line {
            &:first-child {
                transform: rotate(135deg);
            }
            &:nth-child(2) {
                transform: scaleX(0);
                opacity: 0;
            }
            &:last-child {
                transform: rotate(-135deg);
                width: 100%;
            }
        }
    }
}

@keyframes growRight {
    from {
        transform: scaleX(0);
    }
    to {
        transform: none;
    }
}

.opp-social {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin: 0 1ch;
    }
}