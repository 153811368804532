@import 'vars';
@import 'base';
@import 'mixins';

@import 'pages/mixins';

// Layout
@import 'nav';
@import 'shutter';

// Pages
@import 'pages/projects';
@import 'pages/project';
@import 'pages/showreel';
@import 'pages/services';
@import 'pages/clients';

body {
    padding: 0;
    margin: 0;
    color: #124058;
    background: transparent;
    font-size: 16px;
    line-height: 1.62;
    font-family: $font-body;
    font-weight: 200;
    overflow: hidden;

    @include mobile {
        font-size: 16px;
    }
}

a {
    color: #124058;
    text-decoration: none;
}

@for $i from 1 through 1 {
    h#{$i} {
        font-family: $font-heading;
    }
}

@for $i from 2 through 6 {
    h#{$i} {
		font-weight: 400;
    }
}

@for $i from 3 through 6 {
    h#{$i} {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .1ch;
    }
}

button {
    cursor: pointer;
}

.opp-container {
    transition: filter 300ms ease;

    &:after {
        @include pseudo;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: #000;
        opacity: 0;
        pointer-events: none;
        transition: opacity 450ms ease;
    }

    .opp-blur & {
        &:after {
            opacity: .75;
        }
    }
}

.opp-home {
	@include opp-content;

	@include mobile {
		padding-right: 3rem;
	}
}

.opp-parallax {
    // transition: 150ms ease;
    // transform: scale(1.1);
}

.opp-header,
.opp-footer {
    height: $bar-height;
    width: 100%;
    background-color: transparent;
    position: fixed;
    z-index: 2;
}

.opp-header {
    top: 0;
    z-index: 4;
}

.opp-footer {
    bottom: 0;

    &--showreel {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    &--projects {
        display: flex;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        text-transform: uppercase;
        padding: 0 1rem;
    }

    &--button {
        display: inline-block;
        padding: 0;
        background: transparent;
        border: none;
        color: #124058;
        line-height: 1;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        letter-spacing: .1ch;
        font-family: inherit;
        font-size: 1rem;

        img {
            vertical-align: middle;
        }
    }
}

.opp-body {
    height: 100vh;
    // width: 100%;
    // padding: $bar-height 0;
    // position: fixed;
    top: 0;
    // overflow: scroll;
    // overflow-x: hidden;

    .fade-exit.fade-exit-active {
        opacity: 0;
        transition: 300ms ease;
    }
}

// .slide_up-exit>div {
//     transform: translateY(0);
//     transition: 600ms ease;
// }

.slide_up-exit-active>div {
    transform: translateY(-100%);
    transition: 600ms ease;
}

.slide_up-exit-done>div {
    display: none;
}

.slide_up-enter>div {
    transform: translateY(100%);
    transition: 600ms ease;
}

.slide_up-enter-active>div
.slide_up-enter-done>div {
    transform: translateY(0);
    transition: 600ms ease;
}

.slide_up-exit-done>div {
	display: none;
}

.slide_right-exit>div {
    transform: translateX(0);
    transition: 600ms ease;
}

.slide_right-exit-active>div,
.slide_right-exit-done>div {
    transform: translateX(-100%);
    transition: 600ms ease;
}

.slide_right-exit-done>div {
	display: none;
}

.slide_right-enter>div {
    transform: translateX(100%);
    transition: 600ms ease;
}

.slide_right-enter-active>div
.slide_right-enter-done>div {
    transform: translateX(0);
    transition: 600ms ease;
}

.opp-window {
    height: 100vh;
    padding: $bar-height 0;
    background-position: center;
    background-size: cover;
    transform: translateX(0%) translateY(0%);
}

.opp-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
}

.opp-main {
    @include opp-main;
}

.opp-content {
    @include opp-content;
}

.opp-content__small {
	display: block;
	padding-top: 1.5rem;
}

.opp-button {
    @include opp-button;
}

.opp-right {
    position: absolute;
    right: 8rem;
    top: 50%;
    transform: translateY(-50%);
    width: calc(50vw - 8rem);

    @include tablet {
        position: static;
        transform: none;
        width: 100%;
        margin-top: 2rem;
    }
}

.opp-cta {
    @include opp-button;
    font-size: 2rem;
    position: absolute;
    left: 8rem;
    bottom: 1rem;

    @include tablet {
        left: 6rem;
    }

    @include mobile {
        position: static;
    }
}

.opp-video {
    @include opp-video;

    &--wrap {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
    }
}

.opp-loader {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.opp-spinner {
	display: block;
	align-self: center;
	margin: auto;
    height: 4rem;
    width: 4rem;
    border: 2px solid transparent;
    border-left-color: #fff;
    opacity: .3;
    animation:
        spinner infinite 1s linear;
    border-radius: 50%;
}

.opp-mouse {
    path {
        animation: scroll 1200ms linear infinite;
    }
}

@keyframes scroll {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .75;
        transform: translateY(5px);
    }

    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.mobile-hide {
	@include mobile {
		display: none;
	}
}

.opp-content--text:not(:first-child) .opp-content--heading {
	margin-top: 3rem;
}