.opp-projects {
	display: block;
    height: 100%;
    margin: 0;
    background-color: #fff;
    position: relative;

    @include mobile {
        display: block;
        overflow-y: auto;
	}
	
    &--heading {
        @include heading;
    }
	
	&--list {
		display: flex;
		flex-direction: row;
        height: 100%;
        position: absolute;
        transition: transform 300ms ease;
        
        @include mobile {
            display: block;
            height: initial;
            width: 100%;
            transition: none;
        }
	}

    &--intro {
		// width: 400px;
        flex-shrink: 0;
        padding-left: 6rem;
        height: 100%;
        display: flex;
        padding-right: 2rem;
        align-items: center;

        @include mobile {
            width: 100%;
            height: auto;
        }
    }

    &--item {
        width: 700px;
        height: 100%;
        position: relative;
        z-index: 1;
        transition: 300ms ease;
        margin-right: .5rem;
        display: flex;
        flex-direction: column;

        @include large-desktop {
            width: 500px;
        }

        @include tablet {
            font-size: 14px;
        }

        @include mobile {
            font-size: 14px;
            width: 100%;

        }

        &:hover {
            box-shadow: 0 10px 30px rgba(#000, 0.2);
            z-index: 2;
            &:after {
                opacity: 0;
            }
        }
        &--text {
			width: 100%;
			background: #dadde0;
			color: #ffffff;
            //background: linear-gradient(to bottom, rgba(#222,.95), rgba(#222,0.85)), url("https://www.transparenttextures.com/patterns/noise-lines.png");
            padding: 0 2rem;
            overflow: hidden;
            // height: 61.8%;
            flex-grow: 1;
            position: relative;
            font-size: 16px;
            min-height: 200px;
            
            @include tablet {
                font-size: 14px;
            }

            @include mobile {
                min-height: 300px;
                padding-bottom: 2rem;
            }
		}
		
		&--heading {
			font-family: $font-heading;
			font-size: 4rem;
			font-weight: 500;
			line-height: 1;
			letter-spacing: .05ch;
            margin-bottom: 1rem;

            @include small-desktop {
                font-size: 3rem;
			}
			
			@media screen and (max-height: 700px) {
				font-size: 3rem;
			}
            
            @include mobile {
                font-size: 2.4rem;
            }
		}

		&--description {
			// font-size: 16px;
		}

        &--image {
            width: 100%;
            // height: 38.2%;
            background-size: cover;
			background-position: center;
			background-color: #4C637E;
            
            &:before {
                position: relative;
                display: block;
                content: '';
                padding-top: 56.25%;
                @media screen and (max-height: 800px) {
                    padding-top: 40%;
                }
            }

            @include mobile {
				// padding-top: 40%;
				height: calc(100vw * .6);
            }
        }
    }

    &--item--button {
        @include opp-button;

		&--wrap {
			// background-image: linear-gradient(to bottom, transparent, rgba(#444,1));
			position: absolute;
            bottom: 1rem;
            left: 2rem;
            right: 2rem;
		}
		
		@include mobile {
			&--wrap {
				position: static;
			}
		}
    }

    &--scroll {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        * {
            padding-right: 1ch;
        }
        
        @include mobile {
            display: none;
        }
    }
}

.opp-projects--button {
    @include landingButton();
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;

    span {
        display: block;
    }

    img {
        display: block;
        margin-left: 1rem;
    }

    @include tablet {
        // top: initial;
		// bottom: 1rem;
		transform: rotate(-90deg);
		white-space: nowrap;
		right: -3.5rem;
		br {
			display: none;
		}
		img {
			transform: rotate(90deg);
		}
    }
}