.opp-services {
	@include opp-content;
	width: 100%;
	flex-direction: row-reverse;
	justify-content: space-between;

	@include tablet {
		padding: 3rem;
		display: block;
		overflow: auto;
		padding-left: 6rem;
	}

	@include mobile {
		padding: 2rem;
	}

	&--background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: 0 0;
	}

	&--list {
		@include opp-list;
		list-style: none;
		display: block;
		margin-right: 15%;
		@media screen and (max-width: 1440px) {
			margin-right: 5%;
		}
		//margin: 0;
		padding: 0;
		text-align: left;
		font-family: $font-heading;

		@include tablet {
			margin-bottom: 2rem;

			display: none;
			
			&__open {
				display: block;
			}
		}
	}

	&--menu {
		display: none;
		width: 100%;
		font-family: $font-heading;
		font-size: 2em;
		background: transparent;
		border: none;
		color: #fff;
		text-align: left;

		@include tablet {
			display: block;
		}
	}

    position: absolute;
    right: 3em;

    &--item {
		@include opp-list--item;
		
		@include tablet {
			&--link {
				// padding: .25em 0;
			}
		}
	}
	@media screen and (max-width: 1280px), screen and (max-height: 820px) {
		.opp-content--heading {
			font-size: 4rem;
		}
		.opp-content--text {
			font-size: 16px;
		}
		img.ss-htmleditorfield-file.leftAlone {
			display: block;
			margin: 0 auto;
			max-width: 75%;
			height: auto;
		}
	}
	@media screen and (min-width: $break-m) and (max-width: $break-xl) {
		padding-right: 2rem;

		.opp-services--list {
			max-width: calc(45vw - 150px);
			margin-right: 0;
			@media screen and (max-width: $break-l) {
				font-size: 3.5rem;
			}
		}
	}
}
