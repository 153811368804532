.opp-project {
    $top: &;
    min-width: 100%;

    position: fixed;
    top: $bar-height;
	left: 0;
	bottom: $bar-height;
	z-index: 9;
	display: flex;
    flex-direction: row;
    background: #000;
    transition: transform 300ms ease;

    &.fade-enter {
        opacity: 0;
        transition: 300ms ease;
    }
    
    &.fade-enter-active {
        opacity: 1;
        transition: 300ms ease;
    }

    &--block {
		width: 460px;
		background: #ffffff;
		//background: linear-gradient(45deg, #333, #222);
		padding: 0 3rem 3rem 6rem;
        position: relative;

        font-size: 14px;
        @media screen and (max-height: 750px) {
            font-size: 12px;
        }
	}

	&--highlights {
		position: absolute;
		bottom: 2rem;
		right: 3rem;
		left: 6rem;
		text-align: right;
	}
	
	&--video {
		// height: 100%;
		// width: 200px;
        display: flex;
        align-items: center;
        // overflow: hidden;

        video {
            height: auto;
            width: 800px;
        }

        &--thumb {
            width: calc(100vw - 900px);
            min-width: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            cursor: pointer;

            // animation: openThumb 600ms ease-in-out forwards;

            @keyframes openThumb {
                from {
                    width: 0;
                    min-width: 0;
                }
                to {
                    width: calc(100vw - 900px);
                }
            }

            img {
                display: block;
                width: 120px;
                height: auto;
                opacity: .75;
                transition: 300ms ease;
            }

            &:hover img {
                opacity: 1;
            }

            @include tablet {
                animation: none;
                height: 300px;
				width: 100%;
				min-width: 0;
            }
        }
    }

    &--close {
        position: fixed;
        outline: none;
        left: 1.5rem;
        font-size: 3rem;
        text-align: center;
        width: 3rem;
        height: 3rem;
        color: #124058;
        z-index: 100;
        top: 5rem;
        line-height: 1;
        // width: 2rem;
        // height: 2rem;
        display: block;
        border: none;
		background: transparent;
		padding: 0;
		@include mobile {
			display: none;
		}
	}
	
	&--popup {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        top: $bar-height;
        bottom: $bar-height;
        left: 0;
        right: 0;
        background: transparent;

        video {
            height: auto;
            max-height: 100%;
            width: auto;
            max-width: 100%;
        }

	}
	
	&--heading {
		font-family: $font-heading;
		font-size: 3rem;
		font-weight: 500;
		line-height: 1;
		letter-spacing: .05ch;
		margin-bottom: 1rem;
		
		@include mobile {
			font-size: 2.4rem;
		}
	}
    
    &--heading2 {
		display: none;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: .1ch;
        font-weight: bold;
        padding-bottom: 1em;
        line-height: 1;
        margin-top: 2em;
        margin-bottom: 1em;
        position: relative;

        &:after {
            @include pseudo;
            left: 0;
            bottom: -1px;
            height: 2px;
            width: 40px;
            background: #fff;

            #{$top}--highlights & {
                right: 0;
                left: initial;
                @include tablet {
                    left: 0;
                    right: initial;
                }
            }
        }
	}

	&--results {
		display: flex;
		min-height: 100%;

		#{$top}--block {
			min-height: 100%;
			width: 440px;
            padding-left: 3rem;
		}
	}
	
	&--image {
		display: none;
		width: calc(100% - 2rem);
		position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding-top: 60%;
        background-color: #222;
		background-position: center;
		background-size: cover;
	}
    
    @include tablet {
        display: block;
        // top: 0;
        // bottom: 0;
        overflow-y: auto;
        width: 100%;

        &--image {
            position: static;
            margin-top: 3rem;
            width: 100%;
        }

        &--block:first-of-type {
            padding-left: 6rem;
            width: 100%;
        }

        &--block {
            padding-top: 1rem;
            padding-bottom: 3rem;
            padding-left: 6rem;
        }

        &--highlights {
            position: static;
            text-align: left;
        }

        &--block,
        &--video {
            width: 100%;
        }

        &--video {
            height: auto;
		}

		&--results {
			display: block;
			min-height: 0;
		}
	}
	
	@include mobile {
		&--block:first-of-type {
            padding-left: 3rem;
            width: 100%;
        }
	}
}